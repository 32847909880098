// set the color variables

// palette
$blue : #4796CA;
  $light-blue : #4BBBC5;
  $dark-blue : #273D85;
$green : #13C49D;
$grey : #8A7C8C;
$red : #FA4E60;
$white : #FFFFFF;

// related to components
$background : #F6F9FB;
$background-bis : darken($background, 2%);
$dark-background : #2845A6;
$border : #CFDDE6;
$font-color : $grey;

// set the satus
$go : #91C57A;
$warning : #E18E47;
$nogo : #BF4C4C;
