.upload-box {
  padding: 13px;
  @media (max-width: 992px) {
    padding: 0;
  }
  .upload {
    width: calc(50% - 25px);
    padding: 15px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='lightgrey' stroke-width='3' stroke-dasharray='5%2c10' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    @media (max-width: 736px) {
      width: calc(100%);
      margin-bottom: 1rem;
    }
    img{
      max-width: 100px;
      height: auto;
    }
    & + div{
      width: calc(50% - 25px);
      @media (max-width: 736px) {
        width: calc(100%);
      }
    }
  }
}
