// paragraphs
p {
  font-family: $font;
  color: $font-color;
  @include fontSize(14px);
}

// links
a {
  @include darkenColor($light-blue);
}
.overdue{
  color: $nogo;
}
