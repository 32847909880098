.searching-spinner{
  z-index: -2;
  display: flex;
  align-items: center;
  span{
    margin-left: 5px;
    color: rgba($dark-blue, .3);
  }
}

.spinner-border{
  border-color: rgba($dark-blue, .3);
  border-right-color: transparent;
  width: 1.5rem;
  height: 1.5rem;
}