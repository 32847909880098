.btn{
  position: relative;
  @include fontSize(14px);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 5px 12px;
  border-radius: 50px;
  transition: all .3s;
  svg{
    @include fontSize(16px);
  }
  & > *:not(:last-child){
    margin-right: 10px;
  }
  .amount{
    position: absolute;
    top: -3px;
    right: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include fontSize(12px);
    margin: 0;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: $red;
    color: $white;
  }
}

// CTA container
.cta-container{
  margin: 1em;
  & > *:not(:last-child){
    margin-right: 10px;
  }
}

// action button
.btn-primary,
.btn-action{
  @include darkenBG($green);
}

// navigation to another page button
.btn-secondary,
.btn-page{
  @include darkenBG($blue);
}

// navigation to overview
.btn-third{
  @include darkenBG($light-blue);
  color: $white;
  &:hover{
    color: $white;
  }
}

.btn-light{
  @include darkenBG($white);
  color: $grey;
  svg{
    color: $light-blue;
  }
}
//delete
.btn-delete{
  @include darkenBG(transparent);
  color: $red;
  padding: 6px 9px;
  svg{
    color: $red;
  }
}
// Back button
.btn-back{
  @include darkenColor($light-blue);
  padding: 0;
  &::before{
    content: '<';
    margin-right: 5px;
  }
  .overview-box &{
    display: none;
  }
}

// Accordion button
.btn-accordion{
  width: 100%;
  justify-content: flex-start;
  @include fontSize(16px);
  font-weight: 300;
  color: $font-color;
  position: relative;
  padding-right: 55px;
  .chevron{
    color: $light-blue;
    position: absolute;
    top: 9px;
    right: 20px;
    transition: all .3s;
  }
  &[aria-expanded=true]{
    .chevron{
      transform: rotate(180deg);
    }
  }
}

// BTN-SUCCESS
.btn-success{
  @include darkenBG($green);
}

// BTN-DANGER
.btn-danger{
  @include darkenBG($red);
}

// MAIL
.subscriber-mail{
  @include fontSize(12px);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 3px 3px 3px 8px;
  border-radius: 50px;
  transition: all .3s;
  color: $white;
  gap: 5px;
  cursor: pointer;
  .delete-subscriber{
    @include fontSize(14px);
    padding: 1px;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    fa-icon{
      line-height: 20px;
    }
    &:hover{
      background-color: $white;
      color: $grey;
    }
  }
}