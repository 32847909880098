.custom-filter{
    flex-direction: column;
    position: relative;
    z-index: 99;
    & > ul{
        margin: 0;
        padding: 55px 15px 0px;
        list-style: none;
        color: $font-color;
        @include fontSize(14px);
        border: solid 1px $border;
        border-radius: 20px;
        background-color: $white;
        position: absolute;
        top: .5rem;
        left: 0;
        z-index: -1;
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        li p{
            @include darkenColor($light-blue);
            &:hover{
                cursor: pointer;
            }
        }
    }
}