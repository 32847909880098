h1{
  @include fontSize(25px);
  font-weight: 700;
  color: $dark-blue;
  &::after{
    content: '.';
    color: $light-blue;
  }
  .dark-background & {
    color: $white;
  }
}

h2{
  @include fontSize(20px);
  font-weight: 700;
  color: $dark-blue;
  .dark-background & {
    color: $white;
  }
}

h3{
  @include fontSize(20px);
  font-weight: 700;
  color: $grey;
  margin-bottom: 0;
  a & {
    color: $light-blue;
  }
}

h4{
  @include fontSize(14px);
  color: $grey;
  margin-bottom: 0;
}