// BOX SHADOW
@mixin shadow($x, $y, $blur, $color) {
  -webkit-box-shadow: $x $y $blur $color;
  -moz-box-shadow: $x $y $blur $color;
  box-shadow: $x $y $blur $color;
  // @include shadow(1px, 1px, 3px, rgba(0, 0, 0, 1/2))
}

// CONVERT PX TO REM
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// apply to font-size
@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
  //@include fontSize(20px);
}

// apply to line-height
@mixin lineHeight($size) {
  line-height: $size;
  line-height: calculateRem($size);
  //  @include lineHeight(14px);
}

// DARKEN THE BACKGROUND BY HOVERING
@mixin darkenBG($background-color) {
  background-color: $background-color;
  &:disabled{
    background-color: $background-color;
    opacity: .2;
    &:hover{
      background-color: $background-color;
    }
  }
  &:hover,
  &:focus{
    background-color: darken($background-color, 5%);
  }
  // @include darkenBG(red);
}

// DARKEN THE COLOR BY HOVERING
@mixin darkenColor($background-color) {
  color: $background-color;
  &:hover{
    color: darken($background-color, 10%);
  }
  // @include darkenColor(red);
}