html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body{
  background-color: $background;
  position: relative;
  font-family: $font;
  color: $font-color;
  @include fontSize(14px);
  padding: 30px 30px 30px 280px;
  @media (max-width: 992px) {
    padding: 15px;
  }
  hr{
    border-color: $border;
  }
  p{
    margin-bottom: .5rem;
  }
  main{
    padding-top: 89px;
    @media (max-width: 992px) {
      padding-top: 75px;
    }
  }
  ul, li, a, p, button{
    &:empty{
      display: none;
    }
  }
}