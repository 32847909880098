.tab {
  padding: 8px 0;
  @include fontSize(20px);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: $font-color;
  background-color: transparent;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: $light-blue;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: all 0.3s;
  }
  &.active,
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}
