.card-container,
.box {
  background-color: $background-bis;
  border-radius: 10px;
  padding: 20px;
  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.status-box {
  width: 40%;
}

.card-table {
  display: grid;
  grid-template-columns: repeat(auto-fill, 15.5%);
  gap: 20px 10px;
  justify-content: space-between;
  flex-grow: 1;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(auto-fill, 18.5%);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, 23%);
  }
  @media (max-width: 736px) {
    grid-template-columns: repeat(auto-fill, 48%);
  }
  .card, 
  a.card {
    transition: all 0.3s;
    @include shadow(0, 0, 0, rgba($dark-blue, 0));
    border-radius: 10px;
    border: solid 1px $border;
    background-color: $white;
    text-decoration: none;
    position: relative;
    &::after {
      content: "";
      display: flex;
      width: 10px;
      height: 10px;
      border-right: solid 2.5px $border;
      border-bottom: solid 2.5px $border;
      border-radius: 3px;
      position: absolute;
      transform: rotate(-45deg);
      top: 13px;
      right: 12px;
    }
    &:hover {
      @extend .shadowed-box;
    }
    .card-body {
      padding: 10px 30px 0 10px;
      h3,
      h4 {
        margin-bottom: 3px;
      }
      h3 {
        position: relative;
        padding-right: 20px;
        @include fontSize(15px);
        max-height: 36px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      p {
        @include fontSize(12px);
        color: $grey;
        margin-bottom: 3px;
      }
    }
    .card-footer {
      border-radius: 0 0 10px 10px;
      border: none;
      background-color: transparent;
      padding: 10px 10px 10px 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      p {
        @include fontSize(13px);
        color: $grey;
        margin-bottom: 0;
        order: 1;
        &:first-child{
          order: 2;
        }
      }
    }
  }
  .overview-box &{
    grid-template-columns: repeat(auto-fill, calc( 20% - 15px ));
    @media (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, calc( 25% - 15px ));
      .card{
        &:nth-child(5){
          display: none;
        }
      }
    }
    @media (max-width: 1150px) {
      grid-template-columns: repeat(auto-fill, calc( 50% - 15px ));
      .card{
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5){
          display: none;
        }
      }
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(auto-fill, calc( 25% - 15px ));
      .card{
        &:nth-child(3),
        &:nth-child(4){
          display: flex;
        }
      }
    }
    @media (max-width: 850px) {
      grid-template-columns: repeat(auto-fill, calc( 50% - 15px ));
      .card{
        &:nth-child(3),
        &:nth-child(4){
          display: flex;
        }
      }
    }
    @media (max-width: 550px) {
      grid-template-columns: repeat(auto-fill, calc( 100% ));
    }
  }
  .overview-box.line-of-2 &{
    grid-template-columns: repeat(auto-fill, calc( 50% - 10px ));
    @media (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, calc( 100% ));
      // .card{
      //   &:nth-child(2){
      //     display: none;
      //   }
      // }
    }
    // @media (max-width: 1250px) {
    //   .card{
    //     &:nth-child(2){
    //       display: flex;
    //     }
    //   }
    // }
    @media (max-width: 736px) {
      grid-template-columns: repeat(auto-fill, calc( 50% - 10px ));
    }
    @media (max-width: 550px) {
      grid-template-columns: repeat(auto-fill, calc( 100% ));
    }
  }
}

.card {
    @include shadow(0, 0, 0, rgba($dark-blue, 0));
    border-radius: 10px;
    border: solid 1px $border;
    background-color: $white;
    .card-body {
      padding: 15px;
    }
  }
