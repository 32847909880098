.line-of-2 {
  display: flex;
  justify-content: space-between;
  @media (max-width: 736px) {
    flex-direction: column;
  }
  & > * {
    width: calc(50% - 0.5rem);
    @media (max-width: 736px) {
      width: 100%;
    }
    .card-table {
      grid-template-columns: repeat(auto-fill, 31%);
      @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, 48%);
      }
    }
    .line-of-2 {
      @media (max-width: 1300px) {
        flex-direction: column;
      }
      & > * {
        &:first-child {
          width: calc(70% - 0.5rem);

          @media (max-width: 1300px) {
            width: 100%;
          }
        }
        &:last-child {
          width: calc(30% - 0.5rem);

          @media (max-width: 1300px) {
            width: 100%;
          }
        }
      }
    }
  }
}
.line-of-5 {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  @media (max-width: 736px) {
    flex-direction: column;
  }
  & > * {
    flex: 1 0 calc(20%);
    @media (max-width: 736px) {
      width: 100%;
    }
  }
}
.list-of-n {
  display: flex;
  gap: .25rem;
  flex-wrap: wrap;
  & > * {
    flex: 0 0 auto;
  }
}
