// TOOLS
// are used to define scss variables, mixins, extends and functions
// import variables
@import "app/stylesheets/tools/reset";
@import "app/stylesheets/tools/variables";
@import "app/stylesheets/tools/mixins";

// CLASSES
// set different classes that will be used in the theme
@import "app/stylesheets/classes/shadowed";
@import "app/stylesheets/classes/status";

// LAYOUT
// styles the main components of the layout
@import "app/stylesheets/layout/body";
@import "app/stylesheets/layout/my-own-grid";
@import "app/stylesheets/layout/page";
@import "app/stylesheets/layout/titles";
@import "app/stylesheets/layout/content";
@import "app/stylesheets/layout/buttons";
@import "app/stylesheets/layout/form";
@import "app/stylesheets/layout/tabs";
@import "app/stylesheets/layout/table";

// COMPONENTS
// overwrites the bootstrap components
@import "app/stylesheets/components/card";
@import "app/stylesheets/components/upload-box";
@import "app/stylesheets/components/custom-filter";
@import "app/stylesheets/components/searching-spinner";
/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
@import '~quill/dist/quill.bubble.css'; 
// or
@import '~quill/dist/quill.snow.css';
// style for modals
.modal-backdrop,
.modal {
    z-index: 99999 !important;
}
.modal-content {
    margin: 2px auto;
    z-index: 1100 !important;
}

.report-info{
    height: 100vh !important;
    }