// set the status attributes
@mixin textColorPseudoBG($color) {
  color: $color!important;
  &::before, &::after{
    background-color: $color!important;
  }
}

.status{
  display: flex;
  align-items: center;
  &::before{
    content: '';
    display: flex;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: $grey;
    margin-right: 4px;
  }
  // LIST OF STATUS & ASSOCIATED COLORS HAS TO BE DEFINED
  &.finalized,
  &.approved{
    @include textColorPseudoBG($go);
  }
  &.closed{
    @include textColorPseudoBG($go);
  }
  &.requested,
  &.in-Progress{
    @include textColorPseudoBG($warning);
  }
  &.open{
    @include textColorPseudoBG($warning);
  }
  &.overdue{
    @include textColorPseudoBG($nogo)
  }
  &.deleted{
    @include textColorPseudoBG($grey)
  }
  &.hidden{
    @include textColorPseudoBG($grey)
  }
  &.unpaid,
  &.missing-information,
  &.rejected{
    @include textColorPseudoBG($nogo);
  }
}