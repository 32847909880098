ul.table {
  overflow-x: scroll;
  & > li {
    padding: 10px 10px;
    display: flex;
    &.table-head {
      background-color: $background;
      border-radius: 20px;
      @include fontSize(15px);
      font-weight: 700;
      color: $dark-blue;
    }
    &:not(.table-head) {
      &:not(:last-child) {
        border-bottom: solid 1px $background;
      }
    }
    & > * {
      display: flex;
      flex: 1 0 auto;
      padding: 0 10px;
      align-items: center;
      &.col-cell {
        .info-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  // RESPONSIVE TABLE
  @media (max-width: 736px) {
    & > li {
      flex-direction: column;
      border-radius: 10px;
      border: solid 1px $border !important;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &.table-head {
        display: none;
      }
      & > * {
        display: flex;
        justify-content: space-between;
        text-align: right;
        padding: 10px;
        &:not(:last-child) {
          border-bottom: solid 1px $background;
        }
        &::before {
          content: attr(data-label);
          font-weight: 700;
          color: $dark-blue;
          text-align: left;
          flex: 0 0 30%;
        }
        &.col-cell {
          flex-direction: row;
          align-items: flex-start;
          .info-container {
            align-items: flex-end;
          }
        }
      }
    }
  }
}

table.table {
  margin-bottom: 0;
  tr {
    padding: 10px;
    & > *{
      padding: 10px;
      &:first-child{
        padding-left: 20px;
      }
      &:last-child{
        padding-right: 20px;
      }
    }
  }
  .table-head {
    background-color: $background;
    border-radius: 20px;
    @include fontSize(15px);
    font-weight: 700;
    color: $dark-blue;
    & > th {
      vertical-align: middle;
      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  tbody {
    & > tr {
      &:not(:last-child) {
        border-bottom: solid 1px $background;
      }
      .info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  // RESPONSIVE TABLE
  @media (max-width: 736px) {
    tr {
      & > *{
        &:first-child{
          padding-left: 10px;
        }
        &:last-child{
          padding-right: 10px;
        }
      }
    }
    .table-head {
      display: none;
    }
    tbody {
      & > tr {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        border: solid 1px $border !important;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        & > td {
          width: 100% !important;
          display: flex;
          justify-content: space-between;
          &::before {
            content: attr(data-label);
            font-weight: 700;
            color: $dark-blue;
            text-align: left;
            flex: 0 0 30%;
          }
          &:not(:last-child) {
            border-bottom: solid 1px $background;
          }
        }
      }
    }
  }
}
