.sub-header{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  h2{
    margin-bottom: 0;
  }
  & > *:not(:last-child){
    margin-right: 15px;
  }
}