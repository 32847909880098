.form-control {
  height: 40px;
  color: $font-color;
  @include fontSize(14px);
  border: solid 1px $border;
  border-radius: 20px;
  padding: 7px 15px;
  &::placeholder {
    color: rgba($font-color, 0.5);
  }
  &:focus {
    border: solid 1px $light-blue;
  }
  .small-form & {
    height: 30px;
  }
}

.form-check {
  padding-left: 0;
  [type="radio"],
  [type="checkbox"] {
    &:checked,
    &:not(:checked) {
      position: absolute;
      left: -9999px;
      & + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        color: #666;
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 2.5px;
          width: 15px;
          height: 15px;
          border: 1px solid $border;
          background: #fff;
        }
        &::after {
          background: $light-blue;
          border: 1px solid $light-blue;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
    }
    &:not(:checked) {
      & + label {
        &::after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
      }
    }
    &:checked {
      & + label {
        &::after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
    &:disabled {
      & + label {
        color: rgba($font-color, 0.5);
        &::before {
          border: 1px solid rgba($border, 0.5);
        }
      }
    }
  }
  [type="checkbox"] {
    & + label {
      &::before,
      &::after {
        border-radius: 3px;
      }
    }
  }
  [type="radio"] {
    & + label {
      &::before,
      &::after {
        border-radius: 15px;
      }
    }
  }
}

.switch {
  position: relative;
  display: flex;
  align-items: center;
  & > *{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
  p{
    margin-bottom: 0;
  }
  input {
    position: absolute;
    left: -9999px;
    &:checked {
      & + .slider {
        background-color: $light-blue;
        &::before {
          transform: translateX(16px);
        }
      }
    }
  }
  .slider {
    position: relative;
    border-radius: 40px;
    width: 40px;
    height: 24px;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $border;
    transition: all 0.3s;
    &::before {
      position: absolute;
      border-radius: 40px;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.3s;
    }
  }
  &.colored {
    input {
      &:not(:checked) {
        & + .slider {
          background-color: $light-blue;
        }
      }
    }
  }
}

.filter-labelled{
  display: flex;
  flex-direction: column;
  & > label{
    margin-bottom: 5px;
  }
}